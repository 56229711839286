@media (min-width: 1200px) {
    .container {
        max-width: 1700px;
        padding: 0 100px;
    }
}
.hidden-pc{
    display: none;
}
@media (max-width: 1280px) {
    .mb80{
        margin-bottom: 60px;
    }
    .mb100{
        margin-bottom: 70px;
    }
}
@media (max-width: 1200px) {
    .overflow{
        overflow: hidden;
    }
}
@media (max-width: 991px) {
    .overlay {
        display: none
    }
    .box_stream{
        width: 100%;
        padding-right: 0
    }
    .box_300{
        width: 100%;
    }
    .mb80,.mb100{
        margin-bottom: 35px!important;
    }
    .mb60{
        margin-bottom: 30px!important;
    }
    .mb40{
        margin-bottom: 30px!important;
    }
    .mt60{
        margin-top: 30px!important;
    }
    .padding_40{
        padding: 20px 0;
    }
    .line-bottom.mb40{
        margin-bottom: 20px!important;
    }
}
@media (max-width: 576px) {
    .hidden-mb{
        display: none;
    }
    .hidden-pc{
        display: block;
    }
    .pd-24{
        padding: 15px;
    }
    .detail_tin {
        .title-detail{
            font-size: 20px;
        }
        .price-all {
            .all{
                font-size: 24px;
            }
        }
    }
}