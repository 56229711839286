
.topnav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: #030B15;
    transition: all .2s ease-in-out;
    border-bottom: 1px solid rgba(103, 103, 103, .5);
    &.sticky {
        .main-menu{
            top: 52px;
        }
    }
    &.bg-agray{
        background: #fff;
        .main-menu {
            .nav>li{
                .sub_menu{
                    &::before{
                        border-bottom: 1px solid #332F3B;
                        border-right: 1px solid #332F3B;
                    }
                }
                &>a{
                    color: #332F3B;
                }
                &.active{
                    &::before{
                        background: #6610F2;
                    }
                    &>a{color: #6610F2;}
                }
            }
        }
        #vibeji-ham span{
            background: #222;
        }
    }
    .flex_row {
        align-items: center;
        height: 100px;
        justify-content: space-between;
    }

    .logo {
        margin-bottom: 0;
        a{
            display: block;
            font-size: 0;
        }
        img{
            height: 93px;
        }
    }

    #vibeji-ham {
        width: 25px;
        height: 20px;
        position: absolute;
        right: 15px;
        top: 32px;
        transform: rotate(0);
        transition: .5s ease-in-out;
        cursor: pointer;
        display: none;
        margin-right: 0 !important;

        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: #fff;
            border-radius: 9px;
            opacity: 1;
            right: 0;
            transform: rotate(0);
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0;
                width: 55%;
            }

            &:nth-child(2) {
                top: 7px;
                width: 100%;
            }

            &:nth-child(3) {
                top: 14px;
                width: 75%;
            }
        }

        &.open {
            span {
                &:nth-child(1) {
                    top: 9px;
                    width: 100%;
                    transform: rotate(135deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                    right: -30px;
                    width: 100%;
                }

                &:nth-child(3) {
                    top: 9px;
                    width: 100%;
                    transform: rotate(-135deg);
                }
            }
        }
    }
    .flex_right{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 140px;
    }
    .main-menu {
        .nav {
            align-items: center;
            &>li {
                margin-right: 50px;
                display: flex;
                align-items: center;
                position: relative;
                &>a {
                    font-size: 18px;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    position: relative;
                    color: rgba(255, 255, 255, 1);
                }
                .sub_menu {
                    font-size: 0;
                    margin-left: 10px;
                    &::before {
                        content: "";
                        border-bottom: 1px solid rgba(255, 255, 255, 0.85);
                        border-right: 1px solid rgba(255, 255, 255, 0.85);
                        width: 7px;
                        height: 7px;
                        transform: rotate(45deg);
                        display: block;
                    }
                }

                &.active {
                    &>a{color: #00C4F4;}
                }
                &.default{
                    position: relative;
                    .level2{
                        min-width: 200px;
                        box-shadow: 0 2px 8px rgba(0, 0, 0, .1);
                        a{
                            display: block;
                            font-size: 15px;
                            padding: 10px;
                            border-bottom: 1px solid #dfdfdf;
                            &:last-child{
                                border-bottom: 0;
                            }
                        }
                        @media (max-width: 991px) {
                            box-shadow: none;
                            padding-left: 15px;
                            padding-bottom: 5px;
                            a{
                                padding: 10px 0;
                            }
                        }
                    }
                }
                &:last-child{
                    margin-right: 0
                }
            }
        }
        .search-btn{
            margin-right: 20px;
            .icon-svg {
                color: rgba(255,255,255,0.85);
            }
        }
        @media (min-width: 992px) {
            .level2 {
                opacity: 0;
                filter: alpha(opacity=0);
                visibility: hidden;
                overflow: hidden;
                position: absolute;
                top: 100%;
                left: 0;
                margin: 0;
                z-index: 10;
                background: #fff;
                text-align: left;
                padding:0 10px;
                transition-duration: 200ms;
                transition-property: opacity , top , visible;
                transition-timing-function: cubic-bezier(.7,1,.7,1);
                .bg-white {
                    padding: 48px 0;
                    background: #fff;
                    position: relative;
                    z-index: 10;
                }


                [class*='grid__'] {
                    column-gap: 40px;
                    row-gap: 40px;
                }

                .itemt {
                    display: flex;
                    flex-wrap: wrap;

                    .ava {
                        background: #fff;
                        border-radius: 10px;
                        position: relative;
                        width: 56px;
                        height: 56px;

                        img {
                            max-height: 95%;
                            max-width: 95%;
                            width: auto;
                            height: auto;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                        }
                    }

                    .content {
                        width: 300px;
                        width: calc(100% - 56px);
                        padding-left: 24px;

                        h3 {
                            font-size: 16px;
                            font-weight: bold;
                        }

                        p {
                            color: #555770;
                            font-size: 13px
                        }
                    }
                }
            }

            .nav {
                &>li {
                    &:hover {
                        .level2 {
                            opacity: 1;
                            filter: alpha(opacity=100);
                            visibility: visible;
                            top: 64px;
                        }
                    }
                }
            }
        }

        @media (max-width: 1199px) {
            .nav {
                &>li {
                    margin-right: 30px;
                }
            }
        }

        @media (max-width: 991px) {
            position: fixed;
            top: 81px;
            right: -100%;
            width: 300px !important;
            background-color: #030B15 !important;
            overflow-y: scroll;
            color: #4d4d4d;
            -webkit-transition: all .25s ease-in-out;
            transition: all .25s ease-in-out;
            z-index: 1001;
            .level2 {
                display: none;
                [class*='grid__'] {
                    grid-template-columns: repeat(1, 1fr);
                    padding: 5px 1px 15px 15px;
                }

                .itemt {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    .ava {
                        background: #F5F7FA;
                        border-radius: 10px;
                        position: relative;
                        width: 40px;
                        height: 40px;

                        img {
                            max-height: 95%;
                            max-width: 95%;
                            width: auto;
                            height: auto;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                        }
                    }

                    .content {
                        width: calc(100% - 40px);
                        padding-left: 15px;

                        h3 {
                            font-size: 14px;
                            margin-bottom: 0;
                        }

                        p {
                            display: none
                        }
                    }
                }
            }
            .nav {
                display: block;
                padding: 0 15px;
                &>li {
                    padding: 0;
                    margin-right: 0;
                    display: block;
                    position: relative;
                    border-bottom: 1px solid rgba(103, 103, 103, 0.7);

                    &>a {
                        display: block;
                        width: 100%;
                        position: relative;
                        border-radius: 0;
                        height: 100%;
                        line-height: 60px;
                        &::before{
                            display: none;
                        }
                        
                    }

                    .sub_menu {
                        display: block;
                        font-size: 22px;
                        width: 40px;
                        text-align: center;
                        line-height: 50px;
                        position: absolute;
                        right: 0;
                        top: 0px;
                        &::before {
                            display: none
                        }
                    }
                    &:last-child{
                        border-bottom: 0;
                    }
                    &.active {
                        &::before{display: none;}
                        &>a {
                            font-weight: bold;
                        }
                    }
                }
            }
            .search-btn{
                margin-right: 0;
                margin: 20px 0;
                .auto_search_button{
                    display: none;
                }
                .icon-svg{
                    fill: #ccc;
                }
                .search-wrap{
                    display: block;
                    position: static;
                    width: 100%;
                    input{
                        border: 1px solid #e5e5e5;
                        height: 40px;
                    }
                    button{
                        top: 10px;
                    }
                }
            }
            .my-login{
                .btn-default{
                    width: 100%;
                }
            }
            &.open {
                right: 0;
                box-shadow: 0px 0px 5px 0px #ccc
            }
        }
    }
    @media (max-width: 1280px) {
        .flex_row{
            height: 80px;
        }
        .logo {
            img{
                height: 75px;
            }
        }
    }
    @media (max-width: 1025px) {
        .flex_right{gap: 50px;}
    }
    @media (max-width: 991px) {
        #vibeji-ham {
            display: block;
        }
        .flex_right{
            gap: 0;
            .btn-default{
                margin-right: 50px;
            }
        }
    }
    @media (max-width: 767px) {
        .logo{
            img{
                height: 65px;
            }
        }
        .btn-default{
            padding: 0 20px;
        }
    }
}
.search-btn {
    position: relative;
    button {
        border: 0;
        background: none;
    }

    .icon-svg {
        position: relative;
        display: block;
        width: 16px;
        height: 16px;
        top: 7px;
        color: #222;
        -webkit-transition-duration: 400ms;
        transition-duration: 400ms;
        -webkit-transition-property: all;
        transition-property: all;
        -webkit-transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
        transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    }

    .close {
        width: 13px;
        height: 13px;
        opacity: 0 !important;
    }

    .auto_search_button {
        &.is-clicked {
            .icon-svg {
                top: -13px;
                opacity: 0;
            }

            .close {
                opacity: 1 !important;
                top: -7px;
            }
        }
    }

}
.search-wrap {
    position: absolute;
    top: -4px;
    right: 30px;
    width: 312px;
    height: 36px;
    z-index: 2;
    display: none;
    background: rgba(255, 255, 255, 1);
    border-radius: 49px;
    .container {
        background: #fff;
    }

    input {
        background: rgba(255, 255, 255, .1);
        font-size: 14px;
        height: 36px;
        width: 100%;
        outline: 0;
        border: 0;
        padding: 0 30px 0 20px;
        border-radius: 49px;
        @include placeholder {
            
        }
    }

    button {
        position: absolute;
        right: 12px;
        top: 9px;
        outline: 0;

        svg {
            fill: rgba(255, 255, 255, 0.85);
            top: 2px !important;
        }
    }
}


body.active {
    .overlay {
        opacity: 1;
        z-index: 9;
    }
}

.overlay-bg {
    background: rgba(0, 0, 0, .4);
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    z-index: -1;
    opacity: 0;
    -moz-transition: .2s ease-out;
    -o-transition: .2s ease-out;
    -webkit-transition: .2s ease-out;
    -ms-transition: .2s ease-out;
    transition: .2s ease-out;
    &.open{
        z-index: 8;
        opacity: 1;
    }
}