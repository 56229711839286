.footer{
    background: rgba(12, 93, 105, 1);
    padding: 70px 0;
    position: relative;
    z-index: 2;
    .grid__4{
        column-gap: 70px;
        row-gap: 70px;
        .gap{
            width: 400px;
        }
    }
    .title{
        font-size: 20px;
        font-weight: 500;
        color: #b2edfc;
        margin-bottom: 15px;
    }
    .logo{
        display: block;
    }
    ul{
        li{
            margin-bottom: 10px;
            a{
                color: #b2edfc;
                font-size: 14px;
            }
        }
    }
    .social{
        display: flex;
        gap:10px;
        a{
            background: #FFF;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: rgba(234, 109, 35, 1);
            font-size: 22px;
            -webkit-transition: 0.2s all;
            transition: 0.2s all;
            svg{
                width: 20px;height: 20px;
                fill: rgba(234, 109, 35, 1);;
            }
            &:hover{
                background: #00C4F4;
                svg{
                    fill:#fff
                }
            }
        }
    }
    @media (max-width: 1280px) {
        .logo{
            img{
                height: 75px;
            }
        }
    }
    @media (max-width: 1025px) {
        .grid__4{
            column-gap: 30px;
            row-gap: 30px;
            .gap{
                width: 350px;
            }
        }
    }
    @media (max-width: 991px) {
        .grid__4{
            display: flex;
            flex-wrap: wrap;
            column-gap: 0;
            row-gap: 0;
            margin-left: -15px;
            margin-right: -15px;
            .td{
                width: 26%;
                padding: 0 15px;
                margin-bottom: 20px;
                &.gap{
                    width: 48%;
                }
                &:last-child{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .title{
                        margin: 0 10px 0 0;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        padding: 30px 0;
        .grid__4{
            .td{
                width: 50%;
                padding: 0 15px;
                margin-bottom: 20px;
                &.gap{
                    width: 100%;
                }
                &:last-child{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .title{
                        margin: 0 10px 0 0;
                    }
                }
            }
        }
    }
}
.form-send-mail{
    max-width: 100%;
    margin: 0 auto;
    border-radius: 4px;
    border: 1px solid #E2E9F0;
    background: #FFF;
    position: relative;
    input{
        width: 100%;
        outline: 0;
        border: 0;
        background: none;
        height: 45px;
        padding: 0 130px 0 24px;
        @include placeholder{
            color: #6A6377;
        }
    }
    .btn-default{
        position: absolute;
        right: 0;
        width: 120px;
        top: 0;
        padding: 0px 10px;
        height: 45px;
        font-size: 18px;
    }
}